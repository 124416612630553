import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const [number, setNumber] = useState("")
  const [text, setText] = useState("")

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if (typeof number == "undefined") {
      return
    }
    const fnum = async (n) => {
      console.log("toll")
      const res = await fetch(`https://4nqfc0lk81.execute-api.eu-central-1.amazonaws.com/api/num2txt/${n}`)
      const { data } = await res.json()
      setText(data)
    }
    fnum(number)
  }
  const handleChange = (ev) => {
    console.log(ev)
    if (ev.target) {
      setNumber(ev.target.value)
    }
  }
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Olá pessoal.</h1>
      <form onSubmit={handleSubmit} className="max-w-3xl m-3">
        <label>
          Entrar o numero aqui:
          <br />
          <input className="max-w-md" type="text" pattern="[0-9]*" value={number} onChange={handleChange} />
        </label>
        <button className="btn btn-green" type="submit">
          Enviar
        </button>
        {text ? (
          <div className="mt-6 text-3xl">
            <div className="text-gray-600">numero por extenso:</div> {text}
          </div>
        ) : null}
      </form>
    </Layout>
  )
}

export default IndexPage
